// @ts-nocheck
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post } from '../../../../api';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Contact, CreateContact } from '../../../../api/models/contacts';

type ContactsFormProps = {
  type: string;
  id?: number | null;
  idCustomer?: number | null;
  add: (v: CreateContact) => void;
  update: (v: Contact) => void;
};

const ContactForm: React.FC<ContactsFormProps> = (props) => {
  const { type, id, idCustomer, setModalIsVisible, add, update } = props;
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //ENDPOINTS
  const { isLoading: isLoadingContact, data } = useQuery<Contact>([type], () =>
    id ? get<Contact>('/contacts/' + id).then((e) => e.data) : ''
  );
  const { isLoading: isLoadingCustomers, data: customersget } = useQuery<
    Customer[]
  >(['cliente'], () => get<Customer[]>('/customers/').then((e) => e.data));

  useEffect(() => {
    if (customersget) {
      const customersOrdenados = [...customersget];
      // let cust = customersOrdenados.filter((s) => s.customer_PortalCustomer_c);
      customersOrdenados.sort((a, b) => a.name.localeCompare(b.name));
      setCustomers(customersOrdenados);
    }
  }, [customersget]);

  useEffect(() => {
    if (data && type !== 'add' && id && !isLoadingContact) {
      form.setFieldsValue({
        email: data?.email,
        name: data?.name,
        lastName: data?.lastName,
        rol: data?.rol,
        active: data?.active,
        noContact: data?.noContact,
        mainContact: data?.mainContact,
        phone: data?.phone,
        department: data?.department,
        customerId: data?.customerId,
      });
    }
  }, [form, data, id, type, isLoadingContact]);

  useEffect(() => {
    if (idCustomer) {
      form.setFieldsValue({
        customerId: idCustomer,
      });
    }
  }, [idCustomer, form]);

  let updateAction = (data: any) => {
    return get<Contact[]>(`/customers/${idCustomer}/contacts`);
  };

  const query = useQuery<T[]>([`/customers/${idCustomer}/contacts`]);

  const defaultMutationOpts = {
    onSuccess: () => {
      query.refetch();
    },
  };
  const updateMutation = useMutation(
    [`/customers/${idCustomer}/contacts`],
    updateAction,
    defaultMutationOpts
  );

  const addContactFunction = async (values: any) => {
    setIsSubmitting(true);
    try {
      if (type === 'add') {
        // AGREGAR CONTACTO
        let contactRequest: CreateContact = {
          email: values.email,
          password: values.password,
          rol: values.rol,
          name: values.name,
          lastName: values.lastName,
          phone: values.phone,
          department: values.department,
          mainContact: values.mainContact,
          noContact: values.noContact,
          active: values.active,
          customerId: values.customerId,
        };
        if (idCustomer) {
          post('/contacts', values, true)
            .then((e) => {
              if (e.statusCode === 200) {
                updateMutation.mutate(e, {
                  onSuccess: setModalIsVisible(false),
                });
              }
            })
            .finally((_) => setIsSubmitting(false));
        } else {
          add(contactRequest);
        }
      } else {
        // EDITAR CONTACTO
        values['id'] = id;
        update(values);
      }
    } catch (error) {
      console.error('Error al añadir contacto:', error);
    }
  };

  return (
    <Spin
      spinning={isLoadingContact && type !== 'add'}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addContactFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Form.Item
              label="Nombre:"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
              ]}
            >
              <Input
                placeholder="Ingresa tu información"
                disabled={type === 'watch'}
              />
            </Form.Item>
            <Form.Item
              label="Apellido:"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese apellido',
                },
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Teléfono:&nbsp;
                  <Tooltip title="El número telefónico debe contener 10 dígitos">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa número telefónico',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value.toString().length === 10) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'Por favor ingresa número telefónico a 10 dígitos'
                    );
                  },
                }),
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Form.Item
              label="Rol:"
              name="rol"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese rol',
                },
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Form.Item
              label="Departamento:"
              name="department"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese departamento',
                },
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Row>
              <Form.Item
                label="Inactivo:"
                name="active"
                valuePropName="checked"
              >
                <Checkbox disabled={type === 'watch'} />
              </Form.Item>
              <Form.Item
                label="Contacto principal:"
                name="mainContact"
                valuePropName="checked"
              >
                <Checkbox disabled={type === 'watch'} />
              </Form.Item>
              <Form.Item
                label="No contactar:"
                name="noContact"
                valuePropName="checked"
              >
                <Checkbox disabled={type === 'watch'} />
              </Form.Item>
            </Row>
            <Form.Item
              label="Correo electrónico:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingresa tu información"
                disabled={type !== 'add'}
              />
            </Form.Item>
            <Form.Item
              label="Cliente:"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un cliente',
                },
              ]}
            >
              <Select
                disabled={type === 'watch' || idCustomer}
                placeholder="Selecciona una opción"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {customers?.map((s: any) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={
                    type === 'watch' || isLoadingContact || isSubmitting
                  }
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ContactForm;

import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import moment from 'moment';
import { useState } from 'react';
import TablePaginationComponent from '../../../../components/general/table/table-pagination/tablepagination';

const label = 'correo';
const route = '/BulkEmailsSent';
const titlePdf = 'Reporte de correos';
const scrollTable = 500;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const MarketingCampaignList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Asunto',
        width: 100,
        ellipsis: true,
        dataIndex: 'subject',
        key: 'subject',
        ...getColumnSearchProps('subject'),
        render: (subject) => (
          <>
            {subject && (
              <Text>{subject.charAt(0).toUpperCase() + subject.slice(1)}</Text>
            )}
          </>
        ),
      },
      {
        title: 'Enviado por',
        width: 100,
        ellipsis: true,
        dataIndex: 'sentByUserName',
        key: 'sentByUserName',
        ...getColumnSearchProps('sentByUserName'),
        render: (sentByUserName) => (
          <>
            {sentByUserName && (
              <Text>
                {sentByUserName.charAt(0).toUpperCase() +
                  sentByUserName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Enviado a',
        width: 150,
        dataIndex: 'emails',
        key: 'emails',
        ...getColumnSearchProps('emails'),
      },
      {
        title: 'Fecha de envío',
        key: 'sentAt',
        width: 150,
        dataIndex: 'sentAt',
        render: (sentAt) => <>{moment(sentAt).format('DD/MM/YYYY')}</>,
      },
      // {
      //   title: 'Acciones',
      //   key: 'sup',
      //   fixed: 'right',
      //   width: 60,
      //   align: 'center',
      //   render: (user) => (
      //     <>
      //       <Tooltip title="Ver más información">
      //         <Button
      //           type="primary"
      //           ghost
      //           shape="circle"
      //           size="small"
      //           icon={<EyeOutlined />}
      //           onClick={() => showModal('watch', user.id)}
      //         />
      //       </Tooltip>
      //       <Tooltip title="Editar">
      //         <Button
      //           style={{
      //             marginLeft: 10,
      //             backgroundColor: '#537896',
      //             borderColor: '#537896',
      //           }}
      //           type="primary"
      //           shape="circle"
      //           size="small"
      //           icon={<EditOutlined />}
      //           onClick={() => showModal('edit', user.id)}
      //         />
      //       </Tooltip>
      //       <Tooltip title="Eliminar">
      //         <Button
      //           style={{
      //             marginLeft: 10,
      //             backgroundColor: '#e63241',
      //             borderColor: '#cf1322',
      //           }}
      //           type="primary"
      //           shape="circle"
      //           size="small"
      //           icon={<DeleteOutlined />}
      //           onClick={() => handleDelete(user.id)}
      //         />
      //       </Tooltip>
      //     </>
      //   ),
      // },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Correos_enviados',
    sheetName: 'Correos enviados',
    tableName: 'Tabla de Correos enviados',
    columnNames: [
      { label: 'Asunto', key: 'subject' },
      { label: 'Enviado por', key: 'sentByUserName' },
      { label: 'Enviado a', key: 'emails' },
      {
        label: 'Fecha de envío',
        key: 'sentAt',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Asunto', key: 'subject' },
    { header: 'Enviado por', key: 'sentByUserName' },
    { header: 'Enviado a', key: 'emails' },
    {
      header: 'Fecha de envío',
      key: 'sentAt',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
  ];
  const userTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const UsersTable = TablePaginationComponent<User>(userTableProps);

  return UsersTable;
};

export default MarketingCampaignList;

// @ts-nocheck
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CreateTask,
  Customer,
  Opportunity,
  Task,
  User,
  get,
  post,
} from '../../../../api';
import { Contact, CreateContact } from '../../../../api/models/contacts';
import dayjs from 'dayjs';
import { queryClient } from '../../../../config';
import { useParams } from 'react-router-dom';

type TasksFormProps = {
  type: string;
  id?: number | null;
  idCustomer?: number | null;
  idQuote?: number | null;
  add: (v: CreateContact) => void;
  update: (v: Contact) => void;
};

const TaskForm: React.FC<TasksFormProps> = (props) => {
  const { type, id, setModalIsVisible, add, update } = props;
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { type: typePage, id: idDetail } = useParams();

  const statusOptions = [
    { label: 'Abierta', value: 0 },
    { label: 'Completa', value: 1 },
    { label: 'Expirada', value: 2 },
  ];
  const priorityOptions = [
    { label: 'Baja', value: 0 },
    { label: 'Media', value: 1 },
    { label: 'Alta', value: 2 },
  ];
  //ENDPOINTS
  const { isLoading: isLoadingContact, data } = useQuery<Task[]>([type], () =>
    id ? get<Task[]>('/tasks/' + id).then((e) => e.data) : ''
  );
  const { isLoading: isLoadingCustomers, data: customersget } = useQuery<
    Customer[]
  >(['cliente'], () => get<Customer[]>('/customers/').then((e) => e.data));

  const { isLoading: isLoadingUsers, data: usersget } = useQuery<User[]>(
    ['usuario'],
    () => get<User[]>('/users').then((e) => e.data)
  );
  const { isLoading: isLoadingQuotes, data: quotes } = useQuery<Opportunity[]>(
    ['oportunidad'],
    () => get<Opportunity[]>('/quotes').then((e) => e.data)
  );
  useEffect(() => {
    if (customersget) {
      const customersOrdenados = [...customersget];
      customersOrdenados.sort((a, b) => a.name.localeCompare(b.name));
      setCustomers(customersOrdenados);
    }
    if (usersget) {
      const usersOrdenados = [...usersget];
      // const usuariosFiltrados = usersOrdenados.filter(
      //   (user) => user.roles && user.roles.includes('SALES')
      // );
      usersOrdenados.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setUsers(usersOrdenados);
    }
  }, [customersget, usersget]);

  useEffect(() => {
    if (data && type !== 'add' && id && !isLoadingContact) {
      form.setFieldsValue({
        subject: data?.subject,
        relatedTo: data?.relatedTo,
        customerId: data?.customerId,
        expirationDate: dayjs(data.expirationDate, 'YYYY/MM/DD'),
        agentId: data?.agentId,
        status: data?.status,
        priority: data?.priority,
        quoteId: data?.quoteId,
      });
    }
    if (type === 'watch') {
      form.setFieldsValue({
        customerId: data?.customerName,
      });
    }
  }, [form, data, id, type, isLoadingContact]);

  useEffect(() => {
    if (typePage === 'quote') {
      form.setFieldsValue({
        quoteId: parseInt(idDetail),
      });
    } else if (typePage === 'detail') {
      form.setFieldsValue({
        customerId: parseInt(idDetail),
      });
    }
  }, [idDetail, form, typePage]);

  //RECORDAR RECARGAR QUERY
  const handleRefresh = () => {
    queryClient.invalidateQueries(`/tasks`);
  };

  const addTaskFunction = async (values: any) => {
    setIsSubmitting(true);
    if (type === 'add') {
      // AGREGAR TAREA
      let taskRequest: CreateTask = {
        subject: values?.subject,
        relatedTo: values?.relatedTo,
        customerId: values?.customerId,
        expirationDate: values?.expirationDate,
        agentId: values?.agentId,
        status: values?.status,
        priority: values?.priority,
        quoteId: values?.quoteId,
      };
      if (idDetail) {
        post('/tasks', values, true).then((e) => {
          if (e.statusCode === 201) {
            setModalIsVisible(false);
            handleRefresh();
          }
        });
      } else {
        add(taskRequest);
      }
    } else {
      // EDITAR TAREA
      values['id'] = id;
      update(values);
    }
  };

  return (
    <Spin
      spinning={isLoadingContact && type !== 'add'}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addTaskFunction}
      >
        <Divider />
        <Row gutter={16}>
          <Col xs={12} md={12}>
            <Form.Item
              label="Asunto:"
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese asunto',
                },
              ]}
            >
              <Input
                placeholder="Ingresa tu información"
                disabled={type === 'watch'}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Fecha de expiración:"
              name="expirationDate"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione fecha',
                },
              ]}
            >
              <DatePicker
                disabled={type === 'watch'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={12}>
            <Form.Item
              label="Cliente:"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un cliente',
                },
              ]}
            >
              <Select
                disabled={type === 'watch' || typePage === 'detail'}
                placeholder="Selecciona una opción"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {customers?.map((s: any) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {users && (
              <Form.Item
                label="Agente:"
                name="agentId"
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione agente',
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona una opción"
                  allowClear
                  disabled={type === 'watch'}
                  showSearch
                  optionFilterProp="children"
                >
                  {users?.map((e: any) => (
                    <Select.Option key={e.id} value={e.id}>
                      {e.firstName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col xs={20} md={12}>
            <Form.Item
              label="Estatus:"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese estatus.',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                allowClear
                disabled={type === 'watch'}
                showSearch
                options={statusOptions}
                optionFilterProp="children"
              />
            </Form.Item>
          </Col>
          <Col xs={20} md={12}>
            <Form.Item
              label="Prioridad:"
              name="priority"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese prioridad.',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                allowClear
                disabled={type === 'watch'}
                showSearch
                options={priorityOptions}
                optionFilterProp="children"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {users && (
              <Form.Item label="Cotización:" name="quoteId">
                <Select
                  placeholder="Selecciona una opción"
                  allowClear
                  disabled={typePage === 'quote' || type === 'watch'}
                  optionFilterProp="children"
                >
                  {quotes?.map((e: any) => (
                    <Select.Option key={e.id} value={e.id}>
                      #{e.id} - {e.header}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col xs={12} md={12}>
            <Form.Item label="Relacionado con:" name="relatedTo">
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={
                    type === 'watch' || isLoadingContact || isSubmitting
                  }
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default TaskForm;
